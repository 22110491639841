<template>
  <v-card
    title="card-title"
    elevation="1"
    class="none-overflow gole"
    v-ripple
    @click="showDialog"
  >
    <v-card-title class="card-title">
      <span class="card-sub-title">距离</span
      ><span>{{ userPreferrence.mileStone.name }}</span>
    </v-card-title>

    <div class="content">
      <div class="days">{{ days }}</div>
      <div class="day">天</div>
    </div>
    <v-card-actions>
      <v-btn
        rounded
        small
        plain
        class="right-button btn-disabled"
        @click="zzzMid"
        >里程碑
        <v-icon right> mdi-play</v-icon>
      </v-btn>
    </v-card-actions>

    <v-row justify="center" v-show="dialog">
      <v-dialog v-model="dialog" scrollable max-width="25rem">
        <v-card class="rounded-lg none-overflow">
          <v-card-title style="font-weight: normal; background-color: #ffd7af"
            >设置目标
          </v-card-title>
          <!--          <v-divider></v-divider>-->
          <v-card-text>
            <v-row class="pt-8">
              <v-col cols="12">
                <v-text-field
                  class="rounded-lg"
                  label="事件名称"
                  outlined
                  hide-details
                  counter
                  maxlength="5"
                  v-model="tempGole.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-date-picker
                  color="#bbbbbb"
                  style="width: 100%"
                  class="rounded-lg"
                  v-model="tempGole.endTime"
                  :min="getDayStr"
                  :max="String(newDate.getFullYear() + 2) + '-01-01'"
                ></v-date-picker>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog = false">
              取消
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="saveGole"
              :loading="btnLoading"
              :disabled="btnLoading"
            >
              保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import { buttonVibrate } from "@/mixin/mixin";
import { computeToday, deepClone, getDayStr, isObjEqual } from "@/utils/common";
import { mapGetters } from "vuex";
import $api from "@/api/api";

export default {
  name: "mileStone",
  mixins: [buttonVibrate],
  computed: {
    ...mapGetters({
      userPreferrence: "userPreferrence",
    }),
    days() {
      return computeToday(this.userPreferrence.mileStone.endTime);
    },
    getDayStr() {
      return getDayStr();
    },
  },
  data() {
    let newDate = new Date();
    return {
      newDate,
      btnLoading: false,
      dialog: false,
      tempGole: {
        name: "",
        endTime: "",
      },
    };
  },
  methods: {
    showDialog() {
      this.tempGole = deepClone(this.userPreferrence.mileStone);
      this.dialog = true;
    },
    saveGole() {
      this.btnLoading = true;
      if (isObjEqual(this.tempGole, this.userPreferrence.mileStone)) {
        this.btnLoading = false;
        this.dialog = false;
        return;
      }
      let postData = deepClone(this.tempGole);
      $api
        .setUserMileStone(postData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.$toast.success(res.msg);
          } else {
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.btnLoading = false;
          this.$store.dispatch("setUserPreferrencesmileStone", postData);
          this.dialog = false;
        });
    },
  },
  created() {
    $api
      .getUserMileStone()
      .then((ret) => {
        let res = ret.data;
        if (res.code === 0) {
          this.$store
            .dispatch("setUserPreferrencesmileStone", res.data)
            .then(() => {
              this.tempGole = deepClone(this.userPreferrence.mileStone);
            });
        } else {
          this.$toast.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 6rem;
  margin-top: -0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .days {
    font-weight: bold;
    font-family: dosisbold, sans-serif;
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: 0.3rem;
    margin-bottom: 0.65rem;
  }

  .day {
    line-height: 2.5rem;
  }
}

.card-title {
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  color: #404040;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.card-sub-title {
  margin: 0 0.6rem 0 0.2rem;
  font-weight: 300;
  letter-spacing: 0.15rem;
}

.right-button {
  position: absolute;
  right: 0.6rem;
  bottom: 0.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  color: #dc7841;
  font-size: 0.9rem;
  font-weight: 700;
  width: 6.5rem;

  ::v-deep .v-btn__content {
    opacity: 1 !important;
  }
}
</style>
